// @flow

import './index.css';
import svgSprite from './symbol-defs.svg';

import React from 'react';
import classnames from 'classnames';

/**
 * The Icon component defines an icon system with SVG sprites.
 * In the 'symbol-defs.svg' imported above, there are definitions for all icons.
 * Notice in the source of the Icon component that the <use> element is used.
 * It will copy and inline the SVG nodes related to an icon found in the
 * definition file.
 *
 * Related reading:
 * - https://css-tricks.com/svg-sprites-use-better-icon-fonts/
 * - https://kartikprabhu.com/articles/inline-svg-icons
 */

export type Glyph =
  | 'calendar'
  | 'garbage'
  | 'image'
  | 'invoice-circle'
  | 'invoice'
  | 'mail'
  | 'paint-brush-and-palette'
  | 'plus'
  | 'printer'
  | 'question'
  | 'quote-circular-button'
  | 'close';

type Props = {
  glyph: Glyph,
  size?: number,
  className?: string,
};

function Icon({ glyph, size, className }: Props) {
  const symbolId = `icon-${glyph}`;

  // NOTE: SVG 2 removed the need for the xlink namespace, so instead of
  // xlink:href (xlinkHref) for the <use> element we use href.
  // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/xlink:href
  return (
    <svg
      style={size ? { width: `${size}px`, height: `${size}px` } : {}}
      className={classnames('icon', symbolId, className)}
    >
      <use href={`${svgSprite}#${symbolId}`} />
    </svg>
  );
}

export default Icon;
