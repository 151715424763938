import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import type { ImagePosition } from '../types';

function PositionButtonGroup(props: {
  changeLogoPosition: ImagePosition => mixed,
}) {
  return (
    <ButtonGroup className="hidden-print" bsSize="small">
      <Button
        data-testid="logo-position-btn-left"
        onClick={() => props.changeLogoPosition('flex-start')}
      >
        <FormattedMessage id="left" />
      </Button>
      <Button
        data-testid="logo-position-btn-center"
        onClick={() => props.changeLogoPosition('center')}
      >
        <FormattedMessage id="center" />
      </Button>
      <Button
        data-testid="logo-position-btn-right"
        onClick={() => props.changeLogoPosition('flex-end')}
      >
        <FormattedMessage id="right" />
      </Button>
    </ButtonGroup>
  );
}

export default PositionButtonGroup;
