// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export class ItemTableHeader extends PureComponent<{}> {
  render() {
    return (
      <thead>
        <tr>
          <th className="w-5 hidden-print" />
          <th className="w-5">
            <FormattedMessage id="item.ascNumber" />
          </th>
          <th className="w-45">
            <FormattedMessage id="item.service" />
          </th>
          <th className="w-10">
            <FormattedMessage id="item.quantity" />
          </th>
          <th className="w-10">
            <FormattedMessage id="item.cost" />
          </th>
          <th className="w-10">
            <FormattedMessage id="item.vat" />
          </th>
          <th className="w-10">
            <FormattedMessage id="item.total" />
          </th>
        </tr>
      </thead>
    );
  }
}

export default ItemTableHeader;
