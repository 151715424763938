// @flow

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import IconActionButton from './IconActionButton';

type Props = {
  addNewItem: Function,
};

class AddNewItemRow extends Component<Props> {
  render() {
    return (
      <tr className="active hidden-print">
        <td>
          <IconActionButton glyph="plus" onClick={this.props.addNewItem} />
        </td>

        <td colSpan="6">
          <Button
            bsStyle="primary"
            bsSize="small"
            onClick={this.props.addNewItem}
          >
            <FormattedMessage id="item.addNew" />
          </Button>
        </td>
      </tr>
    );
  }
}

export default AddNewItemRow;
