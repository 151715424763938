// @flow

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';

export { emailMask };

// Creates an input mask accepting numbers larger than 0.
export function createNaturalNumberMask() {
  return createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    allowNegative: false,
  });
}

// Creates an input mask of the form DD/MM/YYYY.
export function createDateMask() {
  return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
}

export function createCurrencyMask(currency: string) {
  return createNumberMask({
    prefix: '',
    suffix: currency,
    includeThousandsSeparator: false,
    allowDecimal: true,
    allowNegative: true,
  });
}

export function createSimplePhoneMask() {
  return createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: true,
  });
}

// Custom mask creators --------------------------------------------------------

const nonDigit = /\D/;
const digit = /\d/;
const percent = '%';
const dot = '.';

// Replaces the characters of the string that are digits with the digit regexp.
function maskDigitChars(rawValue = '') {
  return rawValue.split('').map(char => (digit.test(char) ? digit : char));
}

// We don't impose a hard constraint on numbers at the end. If we needed at
// least one number then the input mask would not accept anything unless the
// user first typed a single digit.
const invoiceNumber = /(\D*)(\d*)/;

export function invoiceNumberMask(rawValue: string = '') {
  const result = rawValue.match(invoiceNumber);

  let part1 = [];
  let part2 = [];

  if (!!result) {
    part1 = result[1].split('').map(char => nonDigit);
    part2 = maskDigitChars(result[2]);

    if (part2.length === 0) {
      // The user hasn't added any number yet but we want to make her input
      // valid until she does.
      // For example, the raw value until now is 'Num A -' and she is ready to
      // type the number. In order to enable this we need to add a single
      // digit regexp.
      part2.push(digit);
    }
  }

  return [...part1, ...part2];
}

// We allow a dot without a a digit following. For example the following will be
// accepted: "19.". This will must be accepted and mask by adding a single digit
// at the end.
const vat = /^100$|^(\d{0,2})(\.\d{0,2})?\s*%?$/;

function cleanVatRawValue(rawValue = '') {
  return rawValue
    .replace(percent, '')
    .replace('-', '')
    .replace(' ', '')
    .replace('_', '');
}

/*
 * If the raw value:
 * * is a negative number, the the minus sight is removed and it is treated as a
 *   positive one.
 * * is greater than 100 then the returned mask will accept percentages of the
 *   form dd.dd%
 * * contains more than 2 decimal numbers then the returned mask will accept
 *   percentages of the form dd.dd%
 *
 * In any other case, the raw value is mapped directly to a mask.
 * For example, "0" will map to "d%" and "45.32%" will map to "dd.dd%".
 */
export function vatMask(rawValue: string = '') {
  rawValue = cleanVatRawValue(rawValue);

  if (rawValue.length === 0) {
    return [digit, percent];
  }

  // The value here is for example "34.1233". In this case, we only keep "34.12"
  // to proceed processing.
  if (rawValue.length > 5) {
    rawValue = rawValue.slice(0, 5);
  }

  if (vat.test(rawValue)) {
    const [rawPart1, rawPart2] = rawValue.split(dot);
    let part2 = [];

    if (typeof rawPart2 !== 'undefined') {
      if (rawPart2.length === 0) {
        // The input was of the form "dd.". Note that there's no digit following
        // the dot.
        part2 = [digit];
      } else {
        // The input was of the form "dd.dd".
        part2 = maskDigitChars(rawPart2);
      }

      part2 = [dot, ...part2];
    }

    return [...maskDigitChars(rawPart1), ...part2, percent];
  }

  // As a fallback we return the format "dd.dd%".
  return [digit, digit, dot, digit, digit, percent];
}
