// @flow

import './index.scss';

import React, { Component } from 'react';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { emailMask } from '../masks';

import EmptyInvoiceLabel from '../EmptyInvoiceLabel';
import EditableLabel from '../EditableLabel';

type Props = {
  name?: string,
  job?: string,
  address?: string,
  taxId?: string,
  taxOffice?: string,
  phone?: string,
  email?: string,
};

type State = {
  company: {
    name?: string,
    job?: string,
    address?: string,
    taxId?: string,
    taxOffice?: string,
    phone?: string,
    email?: string,
  },
};

class Company extends Component<Props, State> {
  nameRef: ?EditableLabel;
  jobRef: ?EditableLabel;
  addressRef: ?EditableLabel;
  taxIdRef: ?EditableLabel;
  taxOfficeRef: ?EditableLabel;
  phoneRef: ?EditableLabel;
  emailRef: ?EditableLabel;

  constructor(props: Props) {
    super(props);
    //retrieve from localstorage if any
    const storedCompany =
      window.localStorage && JSON.parse(window.localStorage.getItem('company'));
    const { ...company } = this.props;

    this.state = {
      company: storedCompany || company,
    };
  }

  reset() {
    [
      this.nameRef,
      this.jobRef,
      this.addressRef,
      this.taxIdRef,
      this.taxOfficeRef,
      this.phoneRef,
      this.emailRef,
    ].forEach(ref => {
      if (ref && typeof ref.reset === 'function') {
        ref.reset();
      }
    });
  }

  handleCompanyFieldChange(field: string, value: string) {
    this.setState(
      prevState => {
        return {
          company: {
            ...prevState.company,
            [field]: value,
          },
        };
      },
      () => {
        if (window.localStorage) {
          window.localStorage.setItem(
            'company',
            JSON.stringify(this.state.company)
          );
        }
      }
    );
  }

  render() {
    const {
      name,
      job,
      address,
      taxId,
      taxOffice,
      phone,
      email,
    } = this.state.company;

    const basicFilled = name && job && address && taxId && taxOffice;
    const allEmpty =
      !name && !job && !address && !taxId && !taxOffice && !phone && !email;
    return (
      <Grid fluid>
        {allEmpty && (
          <Row className="company__row visible-print">
            <p className="company__stamp">
              <FormattedMessage id="company.stamp" />
            </p>
          </Row>
        )}

        <Row className="company__row">
          {!name &&
            !allEmpty && (
              <EmptyInvoiceLabel
                label={<FormattedMessage id="company.name" />}
              />
            )}

          <EditableLabel
            data-testid="company-name"
            ref={editableLabel => {
              this.nameRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={name}
            placeholder={<FormattedMessage id="company.name" />}
            className="company__label"
            onChange={value => this.handleCompanyFieldChange('name', value)}
          />
        </Row>

        <Row className="company__row">
          {!job &&
            !allEmpty && (
              <EmptyInvoiceLabel
                label={<FormattedMessage id="company.job" />}
              />
            )}

          <EditableLabel
            data-testid="company-job"
            ref={editableLabel => {
              this.jobRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={200} />}
            initialValue={job}
            placeholder={<FormattedMessage id="company.job" />}
            className="company__label"
            onChange={value => this.handleCompanyFieldChange('job', value)}
          />
        </Row>

        <Row className="company__row">
          {!address &&
            !allEmpty && (
              <EmptyInvoiceLabel label={<FormattedMessage id="address" />} />
            )}

          <EditableLabel
            data-testid="company-address"
            ref={editableLabel => {
              this.addressRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={address}
            placeholder={<FormattedMessage id="address" />}
            className="company__label"
            onChange={value => this.handleCompanyFieldChange('address', value)}
          />
        </Row>

        <Row className="company__row">
          <Col md={4} xs={12}>
            <Row className="company__row">
              {!taxId &&
                !allEmpty && (
                  <EmptyInvoiceLabel label={<FormattedMessage id="taxid" />} />
                )}
              {taxId && (
                <b className="nowrap">
                  <FormattedMessage id="taxid" />
                  :&nbsp;
                </b>
              )}

              <EditableLabel
                data-testid="company-taxid"
                ref={editableLabel => {
                  this.taxIdRef = editableLabel;
                }}
                initialValue={taxId}
                as={props => <input {...props} minLength={0} maxLength={16} />}
                placeholder={<FormattedMessage id="taxid" />}
                className="company__label pr-15"
                onChange={value =>
                  this.handleCompanyFieldChange('taxId', value)
                }
              />
            </Row>
          </Col>

          <Col md={4} xs={12}>
            <Row className="company__row">
              {!taxOffice &&
                !allEmpty && (
                  <EmptyInvoiceLabel
                    label={<FormattedMessage id="taxoffice" />}
                  />
                )}
              {taxOffice && (
                <b className="nowrap">
                  <FormattedMessage id="taxoffice" />
                  :&nbsp;
                </b>
              )}

              <EditableLabel
                data-testid="company-taxoffice"
                ref={editableLabel => {
                  this.taxOfficeRef = editableLabel;
                }}
                as={props => <input {...props} minLength={0} maxLength={70} />}
                initialValue={taxOffice}
                placeholder={<FormattedMessage id="taxoffice" />}
                className="company__label"
                onChange={value =>
                  this.handleCompanyFieldChange('taxOffice', value)
                }
              />
            </Row>
          </Col>
        </Row>

        <Row className="company__row">
          <Col
            md={4}
            xs={12}
            className={cx({ 'hidden-print': !phone && basicFilled })}
          >
            <Row className="company__row">
              {!phone &&
                !basicFilled &&
                !allEmpty && (
                  <EmptyInvoiceLabel label={<FormattedMessage id="phone" />} />
                )}

              <EditableLabel
                data-testid="company-phone"
                ref={editableLabel => {
                  this.phoneRef = editableLabel;
                }}
                as={props => <input {...props} minLength={0} maxLength={70} />}
                initialValue={phone}
                placeholder={<FormattedMessage id="phone" />}
                className="company__label pr-15"
                onChange={value =>
                  this.handleCompanyFieldChange('phone', value)
                }
              />
            </Row>
          </Col>

          <Col md={4} xs={12}>
            <Row className="company__row">
              {!email &&
                !basicFilled &&
                !allEmpty && (
                  <EmptyInvoiceLabel label={<FormattedMessage id="email" />} />
                )}

              <EditableLabel
                data-testid="company-email"
                ref={editableLabel => {
                  this.emailRef = editableLabel;
                }}
                as={props => {
                  return (
                    <MaskedInput {...props} mask={emailMask} guide={true} />
                  );
                }}
                initialValue={email}
                placeholder={<FormattedMessage id="email" />}
                className="company__label"
                onChange={value =>
                  this.handleCompanyFieldChange('email', value)
                }
              />
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }
}
export default Company;
