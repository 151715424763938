// @flow

import './index.css';

import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

type Props = {
  clearForm: () => mixed,
};

class Controls extends Component<Props> {
  render() {
    const { clearForm } = this.props;
    return (
      <div className="container hidden-print">
        <Row>
          <div className="controls text-right">
            <Button
              data-testid="clear-form"
              bsStyle="default"
              onClick={clearForm}
            >
              <FormattedMessage id="clearForm" />
            </Button>

            <Button
              bsStyle="default"
              onClick={() => {
                // FIXME: This onClick handler must be passed as a prop.
                let company =
                  (window.localStorage &&
                    JSON.parse(window.localStorage.getItem('company'))) ||
                  {};
                let invoiceNumber =
                  (window.localStorage &&
                    window.localStorage.getItem('invoiceNumber')) ||
                  '1';
                document.title = 'timologio';
                document.title += (company.name && `-${company.name}`) || '';
                document.title += (invoiceNumber && `-${invoiceNumber}`) || '';

                window.print();
              }}
            >
              <FormattedMessage id="printout" />
            </Button>
          </div>
        </Row>
      </div>
    );
  }
}

export default Controls;
