// @flow

export function identity<T>(value: T): T {
  return value;
}

export const EURO = '€';

export function getCurrentDateFormatted() {
  const now = new Date();

  let month = now.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  return `${now.getDate()}/${month}/${now.getFullYear()}`;
}

export function formatEuros(value: number) {
  return `${value.toFixed(2)}${EURO}`;
}

export function formatVat(value: number) {
  return `${value.toFixed(2)}%`;
}
