// @flow

import type { InvoiceItemId } from './InvoiceItem';
import { InvoiceItem } from './InvoiceItem';

class InvoiceItemList {
  items: Array<InvoiceItem>;

  constructor(items: Array<InvoiceItem> = []) {
    // NOTE: To enforce immutability, items is a read-only non-deletable property
    Object.defineProperty(this, 'items', {
      value: items,
      enumerable: true,
      writable: false,
      configurable: false,
    });
  }

  isEmpty(): boolean {
    return this.items.length === 0;
  }

  get length(): number {
    return this.items.length;
  }

  generateNewItem() {
    return new InvoiceItemList([...this.items, new InvoiceItem()]);
  }

  updateItem(updatedItem: InvoiceItem) {
    const itemIndex = this.items.findIndex(item => item.id === updatedItem.id);

    if (itemIndex === -1) {
      return new InvoiceItemList(this.items);
    }

    const items = [
      ...this.items.slice(0, itemIndex),
      updatedItem,
      ...this.items.slice(itemIndex + 1),
    ];

    return new InvoiceItemList(items);
  }

  removeItem(itemId: InvoiceItemId) {
    const itemIndex = this.items.findIndex(item => item.id === itemId);

    if (itemIndex === -1) {
      return new InvoiceItemList(this.items);
    }

    return new InvoiceItemList([
      ...this.items.slice(0, itemIndex),
      ...this.items.slice(itemIndex + 1),
    ]);
  }

  calculateTotalNetCost() {
    return this.items.reduce(
      (total, item) => total + item.cost * item.quantity,
      0
    );
  }

  calculateTotalVAT() {
    return this.items.reduce((total, item) => total + item.calculateVat(), 0);
  }

  calculateTotalCost() {
    return this.items.reduce((total, item) => total + item.calculateTotal(), 0);
  }

  calculateTaxWithHoldingAmount() {
    const TAX_WITHHOLDING_PERCENT = 0.2;
    return this.calculateTotalNetCost() * TAX_WITHHOLDING_PERCENT;
  }

  calculatePayableAmount() {
    return this.calculateTotalCost() - this.calculateTaxWithHoldingAmount();
  }

  map<T>(
    callbackFn: (currentValue: InvoiceItem, index?: number) => T
  ): Array<T> {
    return this.items.map(
      (currentValue, index) => callbackFn(currentValue, index),
      this
    );
  }

  find(
    callbackFn: (element: InvoiceItem, index?: number) => any
  ): InvoiceItem | void {
    return this.items.find(
      (element, index) => callbackFn(element, index),
      this
    );
  }
}

export default InvoiceItemList;
