// @flow

import React, { Component } from 'react';

import { addLocaleData, IntlProvider } from 'react-intl';
import el from 'react-intl/locale-data/el';
import en from 'react-intl/locale-data/en';
import elMessages from '../i18n/el';
import enMessages from '../i18n/en';

import PageHeader from './PageHeader';
import Controls from './Controls';
import PageFooter from './PageFooter';
import DefaultTheme from './DefaultTheme';

import type { InvoiceItemId } from '../domain';
import { InvoiceItem, InvoiceItemList } from '../domain';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-22584116-3');
ReactGA.ga('send', 'pageview'); // Global site tag (gtag.js) - Google Analytics

addLocaleData([...el, ...en]);

type State = {
  invoiceItemList: InvoiceItemList,
  hasTaxWithHolding: boolean,
  modalType: ?string,
  locale: string,
  messages: { [string]: string },
};

// NOTE: This component is the main component where controls will be added later
// on to select for example different themes.
class App extends Component<{}, State> {
  addNewItem: () => void;
  updateItem: (item: InvoiceItem) => void;
  removeItem: (itemId: InvoiceItemId) => void;
  openModal: (modalType: string) => void;
  closeModal: () => void;
  toggleTaxWithHolding: () => mixed;
  clearForm: () => mixed;
  onLanguageChange: string => void;
  defaultThemeRef: ?DefaultTheme;

  constructor(props: {}) {
    super(props);

    this.state = {
      invoiceItemList: new InvoiceItemList(),
      hasTaxWithHolding: false,
      modalType: undefined,
      locale: 'el',
      messages: elMessages,
    };

    this.addNewItem = this.addNewItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleTaxWithHolding = this.toggleTaxWithHolding.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  addNewItem() {
    this.setState(prevState => ({
      invoiceItemList: prevState.invoiceItemList.generateNewItem(),
    }));
  }

  updateItem(item: InvoiceItem) {
    this.setState(prevState => ({
      invoiceItemList: prevState.invoiceItemList.updateItem(item),
    }));
  }

  removeItem(itemId: InvoiceItemId) {
    this.setState(prevState => ({
      invoiceItemList: prevState.invoiceItemList.removeItem(itemId),
    }));
  }

  openModal(modalType: string) {
    this.setState(prevState => ({
      modalType: modalType,
    }));
  }

  closeModal(modalType: string) {
    this.setState(prevState => ({
      modalType: '',
    }));
  }

  toggleTaxWithHolding() {
    this.setState(prevState => ({
      hasTaxWithHolding: !prevState.hasTaxWithHolding,
    }));
  }

  clearForm() {
    if (
      this.defaultThemeRef &&
      typeof this.defaultThemeRef.reset === 'function'
    ) {
      this.defaultThemeRef.reset();
    }
    this.setState(prevState => ({
      invoiceItemList: new InvoiceItemList(),
      hasTaxWithHolding: false,
    }));
  }

  onLanguageChange(locale: string) {
    this.setState(prevState => ({
      locale,
      messages: locale === 'en' ? enMessages : elMessages,
    }));
  }

  componentDidMount() {
    window.onbeforeprint = () =>
      ReactGA.ga('send', 'event', 'invoice', 'print');
  }

  render() {
    const { locale, messages } = this.state;

    return (
      <IntlProvider locale={locale} messages={{ ...messages }}>
        <div>
          <div className="app-without-footer__container">
            <PageHeader onLanguageChange={this.onLanguageChange} />
            <Controls clearForm={this.clearForm} />
            <DefaultTheme
              ref={defaultTheme => (this.defaultThemeRef = defaultTheme)}
              invoiceItemList={this.state.invoiceItemList}
              addNewItem={this.addNewItem}
              updateItem={this.updateItem}
              removeItem={this.removeItem}
              hasTaxWithHolding={this.state.hasTaxWithHolding}
              toggleTaxWithHolding={this.toggleTaxWithHolding}
            />
            <Controls clearForm={this.clearForm} />
          </div>
          <PageFooter />
        </div>
      </IntlProvider>
    );
  }
}

export default App;
