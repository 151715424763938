// @flow

import React, { Component } from 'react';

import { Button, Glyphicon } from 'react-bootstrap';

export type Action = 'plus' | 'remove';

type Props = {
  onClick: () => mixed,
  glyph: Action,
  className: string,
};

class IconActionButton extends Component<Props> {
  render() {
    return (
      <Button
        bsStyle="link"
        bsSize="small"
        className={this.props.className}
        onClick={this.props.onClick}
      >
        <Glyphicon glyph={this.props.glyph} />
      </Button>
    );
  }
}

export default IconActionButton;
