// @flow

import type { LogoFile } from './types';

/**
 * This interface abstracts away the place where a logo is loaded from or stored
 * to. This abstraction removes the hard dependency on local storage for the
 * LogoDropzone component.
 */
export interface LogoRepository {
  loadlogo(): ?LogoFile;
  storeLogo(logo: ?LogoFile): mixed;
}

/**
 * The default implementation of the LogoRepository interface uses the local
 * storage of the browser if it is defined.
 */
export class LocalStorageLogoRepository implements LogoRepository {
  loadlogo(): ?LogoFile {
    if (
      window.localStorage &&
      typeof window.localStorage.getItem === 'function'
    ) {
      return JSON.parse(window.localStorage.getItem('companyLogo'));
    }
  }

  storeLogo(logo: ?LogoFile) {
    if (
      window.localStorage &&
      typeof window.localStorage.setItem === 'function'
    ) {
      window.localStorage.setItem('companyLogo', JSON.stringify(logo));
    }
  }
}
