// @flow
import React from 'react';
import { Row } from 'react-bootstrap';

import InvoiceTotalValue from './InvoiceTotalValue';

type Props = {
  isEmptyInvoice: boolean,
  totalCostNoVat?: string,
  vat?: string,
  totalCostWithVat?: string,
};

function InvoiceTotalMainPart({
  isEmptyInvoice,
  totalCostNoVat,
  vat,
  totalCostWithVat,
}: Props) {
  return (
    <React.Fragment>
      <Row className="invoice-total__row">
        <InvoiceTotalValue
          isEmptyInvoice={isEmptyInvoice}
          labelId="invoice.netValue"
          value={totalCostNoVat}
          data-testid="invoice-total-cost-no-vat"
        />
      </Row>

      <Row className="invoice-total__row">
        <InvoiceTotalValue
          isEmptyInvoice={isEmptyInvoice}
          labelId="invoice.vat"
          value={vat}
          data-testid="invoice-total-vat"
        />
      </Row>

      <Row className="invoice-total__row">
        <InvoiceTotalValue
          isEmptyInvoice={isEmptyInvoice}
          labelId="invoice.total"
          value={totalCostWithVat}
          data-testid="invoice-total-cost-with-vat"
        />
      </Row>
    </React.Fragment>
  );
}

export default InvoiceTotalMainPart;
