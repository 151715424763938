// @flow

import React from 'react';
import { Row, Col, Well } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

type Props = {
  labelId: string,
  isEmptyInvoice: boolean,
  value?: string,
  'data-testid': string,
};

function InvoiceTotalValue(props: Props) {
  return (
    <React.Fragment>
      <Col xsOffset={6} xs={3} className="invoice-total__label">
        <strong>
          <FormattedMessage id={props.labelId} />
          &#58;
        </strong>
      </Col>

      <Col xs={3}>
        <Row>
          <Well bsSize="small" className="text-right invoice-total__value">
            <span
              data-testid={props['data-testid']}
              className={cx({ 'hidden-print': props.isEmptyInvoice })}
            >
              {props.value}
            </span>
          </Well>
        </Row>
      </Col>
    </React.Fragment>
  );
}

export default InvoiceTotalValue;
