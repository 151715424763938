//@ flow

import './index.scss';

import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import classNames from 'classnames';

type Option = { value: string, label: string };
export type Options = $ReadOnlyArray<Option>;

type Props = {
  className?: string,
  initialValue: string,
  options: Options,
};

type State = {
  value: string,
};

/*
 * The component shows in the browser a dropdown whilte when in print mode it
 * shows a header containing the selected value.
 */
class PrintableDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.getSelectedValue(),
    };
  }

  static defaultProps = {
    initialValue: '',
    options: [],
  };

  onChange(value: string) {
    this.setState(() => ({ value }));
  }

  reset() {
    this.setState(() => ({ value: this.getSelectedValue() }));
  }

  getOptionByValue(value) {
    return (
      this.props.options.find(option => option.value === value) || undefined
    );
  }

  getSelectedValue() {
    const selectedOption = this.getOptionByValue(this.props.initialValue);
    const selectedOptionValue = selectedOption && selectedOption.value;
    //get selected option if initialvalue is correct or
    //the first value of the options array
    return (
      selectedOptionValue ||
      (this.props.options.length > 0 && this.props.options[0].value)
    );
  }

  render() {
    const { value } = this.state;

    const selectedOption = this.getOptionByValue(value);
    const label = (selectedOption && selectedOption.label) || '';

    return (
      <div className={classNames('selectable-label', this.props.className)}>
        <div className="hidden-print selectable-label__control">
          <FormControl
            data-testid="selectable-input"
            componentClass="select"
            value={this.state.value}
            onChange={(e: SyntheticInputEvent<>) =>
              this.onChange(e.target.value)
            }
            autoFocus
          >
            {this.props.options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormControl>
        </div>

        <h2
          data-testid="selectable-label"
          className={classNames('visible-print-block selectable-label__label', {
            'text-muted': !this.state.value,
            'hidden-print': !this.state.value,
          })}
        >
          {label}
        </h2>
      </div>
    );
  }
}

export default PrintableDropdown;
