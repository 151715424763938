// @flow

import './index.scss';

import React, { Component } from 'react';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-bootstrap';
import AutosizeTextArea from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';

import PrintableDropdown from '../PrintableDropdown';
import EditableLabel from '../EditableLabel';
import ItemTable from '../ItemTable';
import Company from '../Company';
import Client from '../Client';
import InvoiceMeta from '../InvoiceMeta';
import InvoiceTotal from '../InvoiceTotal';

import LogoDropzone from '../LogoDropzone';
import type { LogoRepository } from '../LogoDropzone/LogoRepository';
import { LocalStorageLogoRepository } from '../LogoDropzone/LogoRepository';
import type { LogoFile } from '../LogoDropzone/types';

import type { InvoiceItemId, InvoiceItem, InvoiceItemList } from '../../domain';
import { identity, getCurrentDateFormatted, formatEuros } from '../../utils';

type Props = {
  invoiceItemList: InvoiceItemList,
  addNewItem: () => void,
  updateItem: (item: InvoiceItem) => void,
  removeItem: (itemId: InvoiceItemId) => void,
  hasTaxWithHolding: boolean,
  toggleTaxWithHolding: () => mixed,
};

type State = {
  logo?: ?LogoFile,
};

class DefaultTheme extends Component<Props, State> {
  companyRef: ?Company;
  clientRef: ?typeof Client;
  invoiceMetaRef: ?typeof InvoiceMeta;
  selectableLabelRef: ?typeof PrintableDropdown;
  onLogoUpdate: (logo: ?LogoFile) => void;
  repository: LogoRepository;

  constructor(props: Props) {
    super(props);

    this.repository = new LocalStorageLogoRepository();
    this.state = {
      logo: null,
    };

    this.onLogoUpdate = this.onLogoUpdate.bind(this);
  }

  reset() {
    [
      this.companyRef,
      this.clientRef,
      this.invoiceMetaRef,
      this.selectableLabelRef,
    ].forEach(ref => {
      if (ref && typeof ref.reset === 'function') {
        ref.reset();
      }
    });
  }

  onLogoUpdate(logo: ?LogoFile) {
    this.setState(prevState => ({
      logo,
    }));
  }

  render() {
    const {
      invoiceItemList,
      addNewItem,
      updateItem,
      removeItem,
      hasTaxWithHolding,
      toggleTaxWithHolding,
    } = this.props;

    const { logo } = this.state;

    return (
      <Grid className="app__container">
        <Row>
          <div className="app__header">
            <PrintableDropdown
              options={[
                {
                  value: 'invoice',
                  label: (
                    <FormattedMessage id="invoice">{identity}</FormattedMessage>
                  ),
                },
                {
                  value: 'goodsInvoice',
                  label: (
                    <FormattedMessage id="goodsInvoice">
                      {identity}
                    </FormattedMessage>
                  ),
                },
                {
                  value: 'servicesInvoice',
                  label: (
                    <FormattedMessage id="servicesInvoice">
                      {identity}
                    </FormattedMessage>
                  ),
                },
                {
                  value: 'servicesReceipt',
                  label: (
                    <FormattedMessage id="servicesReceipt">
                      {identity}
                    </FormattedMessage>
                  ),
                },
                {
                  value: 'servicesReceiptMaterials',
                  label: (
                    <FormattedMessage id="servicesReceiptMaterials">
                      {identity}
                    </FormattedMessage>
                  ),
                },
                {
                  value: 'offer',
                  label: (
                    <FormattedMessage id="offer">{identity}</FormattedMessage>
                  ),
                },
              ]}
              ref={selectableLabel => {
                this.selectableLabelRef = selectableLabel;
              }}
            />
          </div>
        </Row>

        <Row>
          <Col xs={6} md={6} className={cx({ 'hidden-print': !logo })}>
            <LogoDropzone
              onChange={this.onLogoUpdate}
              repository={this.repository}
            />
          </Col>

          <Col xs={6} md={6}>
            <Company
              ref={company => {
                this.companyRef = company;
              }}
            />
          </Col>
        </Row>

        <hr />

        <Row className="mt-20 mb-20">
          <Col xs={6} md={6}>
            <Client
              ref={node => {
                this.clientRef = node;
              }}
            />
          </Col>
          <Col xs={6} md={6}>
            <InvoiceMeta
              ref={node => {
                this.invoiceMetaRef = node;
              }}
              number=""
              date={getCurrentDateFormatted()}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <ItemTable
              items={invoiceItemList}
              addNewItem={addNewItem}
              removeItem={removeItem}
              updateItem={updateItem}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={10} xsOffset={2} md={6} mdOffset={6}>
            <InvoiceTotal
              isEmptyInvoice={invoiceItemList.items.length === 0}
              totalCostNoVat={formatEuros(
                invoiceItemList.calculateTotalNetCost()
              )}
              vat={formatEuros(invoiceItemList.calculateTotalVAT())}
              totalCostWithVat={formatEuros(
                invoiceItemList.calculateTotalCost()
              )}
              taxWithHolding={formatEuros(
                invoiceItemList.calculateTaxWithHoldingAmount()
              )}
              payableAmount={formatEuros(
                invoiceItemList.calculatePayableAmount()
              )}
              hasTaxWithHolding={hasTaxWithHolding}
              toggleTaxWithHolding={toggleTaxWithHolding}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} className="mt-20 mb-20">
            <EditableLabel
              as={AutosizeTextArea}
              multiline
              placeholder={<FormattedMessage id="invoice.addNotes" />}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} className="mb-20">
            <EditableLabel
              as={AutosizeTextArea}
              multiline
              placeholder={<FormattedMessage id="invoice.addTerms" />}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default DefaultTheme;
