// @flow

import './index.scss';
import timologoLogo from '../../images/timologo_logo_white.png';

import React, { Component } from 'react';
import { Navbar, Nav, NavItem, Image, Grid, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Icon from '../Icon';
import LanguageSwitcher from './LanguageSwitcher';

import type { IntlShape } from 'react-intl';

type Props = {
  onLanguageChange?: string => void,
  intl: IntlShape,
};

class PageHeader extends Component<Props> {
  render() {
    const { intl } = this.props;
    document.title = intl.formatMessage({ id: 'head.title' });

    return (
      <Navbar className="page-header__container">
        <Navbar.Header>
          <Image
            data-testid="page-header-logo"
            width="200"
            src={timologoLogo}
            className="my-15"
            responsive
          />
        </Navbar.Header>

        <Nav pullRight>
          <NavItem componentClass="span">
            <Grid fluid className="my-10">
              <Row className="page-header__moto">
                <Icon
                  glyph="quote-circular-button"
                  size={28}
                  className="mr-10"
                />

                <div
                  data-testid="page-header-moto"
                  className="page-header__moto__container"
                >
                  <h1>{intl.formatMessage({ id: 'header.moto' })}</h1>
                  <span className="page-header__moto__text--small">
                    <FormattedMessage
                      id="header.moto2"
                      className="page-header__moto__text--small"
                    />
                    !
                  </span>
                </div>

                <LanguageSwitcher
                  onLanguageChange={this.props.onLanguageChange}
                />
              </Row>
            </Grid>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default injectIntl(PageHeader);
