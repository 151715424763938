// @flow

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';

import './global.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui/App';
import registerServiceWorker from './registerServiceWorker';

const root = document.getElementById('root');

if (root === null) {
  throw new Error('HTML element with id `root` is not present in the markup.');
}

ReactDOM.render(<App />, root);

registerServiceWorker();
