// @flow

import './index.scss';
import tau from '../../images/tau.png';

import React, { Component } from 'react';
import { Image, Grid, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { PrivacyPolicyModal, TermsOfUseModal } from './components';
import CookieConsent, { Cookies } from 'react-cookie-consent';

type Props = {};
type State = {
  isTermsOfUseModalOpen: boolean,
  isPrivacyPolicyModalOpen: boolean,
};

class PageFooter extends Component<Props, State> {
  openTermsOfUseModal: () => void;
  closeTermsOfUseModal: () => void;
  openPrivacyPolicyModal: () => void;
  closePrivacyPolicyModal: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isTermsOfUseModalOpen: false,
      isPrivacyPolicyModalOpen: false,
    };

    this.openTermsOfUseModal = () =>
      this.setState({ isTermsOfUseModalOpen: true });
    this.closeTermsOfUseModal = () =>
      this.setState({ isTermsOfUseModalOpen: false });

    this.openPrivacyPolicyModal = () =>
      this.setState({ isPrivacyPolicyModalOpen: true });
    this.closePrivacyPolicyModal = () =>
      this.setState({ isPrivacyPolicyModalOpen: false });
  }

  render() {
    return (
      <footer className="footer hidden-print">
        <CookieConsent
          location="bottom"
          buttonText=<FormattedMessage id="cookiesAcceptBtn" />
          cookieName="myAwesomeCookieName2"
          style={{ background: '#9B0042', fontSize: '13px' }}
          buttonStyle={{
            background: '#bd0051',
            color: '#fff',
            fontSize: '13px',
          }}
          expires={150}
        >
          <FormattedMessage id="cookiesInfoTxt" />{' '}
          <span
            style={{ fontSize: '11px', cursor: 'pointer' }}
            className="about__item"
            data-testid="page-footer-privacy-link"
            onClick={this.openPrivacyPolicyModal}
          >
            <FormattedMessage id="cookiesLearnMore" />
          </span>
        </CookieConsent>
        <Grid>
          <a
            href="https://webtrails.gr/"
            title="Webtrails Digital Innovation Agency"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Col xs={12} sm={4} className="footer-info__container">
              <div className="footer-logo mt-20">
                <div
                  data-testid="page-footer-webtrails-logo"
                  className="footer-logo__image-container"
                >
                  <div className="poweredby-image" />
                  <div className="poweredby-text" />
                </div>
                <div className="quote__container">
                  <div className="love-text">
                    <FormattedMessage id="footer.loveTitle" />
                  </div>
                  <div className="icon-heart" />
                </div>
                <div className="clear" />
                <span>
                  <FormattedMessage id="footer.loveText" />
                </span>
              </div>
            </Col>
          </a>
          <a
            href="https://reactjs.org//"
            title="Webtrails development with React"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Col xs={12} sm={4} className="footer-info__container">
              <div className="react-logo__image" />
              <h3>
                <FormattedMessage id="footer.reactTitle" />
              </h3>
              <span>
                <FormattedMessage id="footer.reactText" />
              </span>
            </Col>
          </a>
          <a
            href="https://webtrails.gr/"
            title="Webtrails Digital Innovation Agency"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Col xs={12} sm={4} className="footer-info__container">
              <div className="strategy-logo__image" />
              <h3>
                <FormattedMessage id="footer.logisticsTitle" />
              </h3>
              <span>
                <FormattedMessage id="footer.logisticsText" />
              </span>
            </Col>
          </a>
        </Grid>
        <div className="footer__subcontainer">
          <Grid>
            <Col xs={12} sm={6}>
              <div className="footer-left">
                <Image
                  data-testid="page-footer-logo"
                  style={{ width: '38px', height: '50px' }}
                  src={tau}
                  className="my-15"
                  responsive
                />
                <div className="footer-left__links">
                  <FormattedMessage
                    id="aboutService"
                    values={{
                      webtrails: (
                        <a
                          data-testid="page-footer-about-link"
                          href="https://webtrails.gr"
                        >
                          Webtrails
                        </a>
                      ),
                    }}
                  />
                  <div className="about">
                    <p
                      className="about__item"
                      data-testid="page-footer-terms-link"
                      onClick={this.openTermsOfUseModal}
                    >
                      <FormattedMessage id="termsOfUse" />
                    </p>
                    <p
                      className="about__item"
                      data-testid="page-footer-privacy-link"
                      onClick={this.openPrivacyPolicyModal}
                    >
                      <FormattedMessage id="privacyPolicy" />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="social__container">
                <p className="social__txt-msg">
                  <FormattedMessage id="socialMessage" />
                </p>
                <div className="mt-20 mr-1 youtube-logo pull-right">
                  <a
                    href="https://www.youtube.com/channel/UCFuUwLF7Jmj1BgdHnLSAvXQ"
                    title="timologo.gr | youtube page"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="footer-social__image-container" />
                  </a>
                </div>
                <div className="mt-20 instagram-logo pull-right">
                  <a
                    href="https://www.instagram.com/timologo.gr/"
                    title="timologo.gr | instagram page"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="footer-social__image-container" />
                  </a>
                </div>
                <div className="mt-20 mr-1 fb-logo pull-right">
                  <a
                    href="https://www.facebook.com/timologo.gr/"
                    title="timologo.gr | facebook page"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="footer-social__image-container" />
                  </a>
                </div>
                <div className="clear" />
              </div>
            </Col>
          </Grid>
        </div>
        <PrivacyPolicyModal
          show={this.state.isPrivacyPolicyModalOpen}
          onClose={this.closePrivacyPolicyModal}
        />
        <TermsOfUseModal
          show={this.state.isTermsOfUseModalOpen}
          onClose={this.closeTermsOfUseModal}
        />
      </footer>
    );
  }
}

export default PageFooter;
