// @flow

import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

type Props = {
  show: boolean,
  onClose?: () => void,
};

function TermsOfUseModal({ show, onClose }: Props) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="termsOfUse" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <FormattedMessage id="termsOfUseDescription">
            {(text: string) =>
              // The text that is associated with `termsOfUseDescription`
              // contains "\n" to separate paragraphs.
              text
                .split('\n')
                .map((paragraph, index) => <p key={index}>{paragraph}</p>)
            }
          </FormattedMessage>
        </div>
      </Modal.Body>
    </Modal>
  );
}

TermsOfUseModal.defaultProps = {
  show: false,
};

export default TermsOfUseModal;
