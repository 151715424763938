// @flow

import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

class PrivacyPolicy extends PureComponent<{}> {
  render() {
    return (
      <div>
        <FormattedMessage id="eServicesTitle">
          {text => <h2>{text}</h2>}
        </FormattedMessage>
        <FormattedMessage id="eServicesDescription">
          {text => <p>{text}</p>}
        </FormattedMessage>
        <FormattedMessage id="localStorageTitle">
          {text => <h2>{text}</h2>}
        </FormattedMessage>
        <FormattedMessage id="localStorageDescription">
          {text => <p>{text}</p>}
        </FormattedMessage>
        <FormattedMessage id="cookiesTitle">
          {text => <h2>{text}</h2>}
        </FormattedMessage>
        <FormattedMessage id="cookiesDescription">
          {text => <p>{text}</p>}
        </FormattedMessage>
        <FormattedMessage id="cookiesAboutQuestion">
          {text => <h3>{text}</h3>}
        </FormattedMessage>
        <FormattedMessage id="cookiesAboutAnswer">
          {text => <p>{text}</p>}
        </FormattedMessage>
        <FormattedMessage id="cookiesUsageQuestion">
          {text => <h3>{text}</h3>}
        </FormattedMessage>
        <FormattedMessage id="cookiesUsageAnswer">
          {text => <p>{text}</p>}
        </FormattedMessage>
        <FormattedMessage id="cookiesCheckQuestion">
          {text => <h3>{text}</h3>}
        </FormattedMessage>
        <FormattedMessage id="cookiesCheckAnswer">
          {text => <p>{text}</p>}
        </FormattedMessage>
      </div>
    );
  }
}

type Props = {
  show: boolean,
  onClose?: () => void,
};

function PrivacyPolicyModal({ show, onClose }: Props) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="privacyPolicy" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PrivacyPolicy />
      </Modal.Body>
    </Modal>
  );
}

PrivacyPolicyModal.defaultProps = {
  show: false,
};

export default PrivacyPolicyModal;
