import React from 'react';

import Icon from '../../Icon';

function RemoveButton(props: { onClick: () => mixed }) {
  return (
    <div
      data-testid="logo-remove-button"
      onClick={props.onClick}
      className="dropzone__clear__container hidden-print"
    >
      <Icon glyph="close" size={24} className="dropzone__clear__button" />
    </div>
  );
}

export default RemoveButton;
