// @flow

import './index.scss';

import React, { Component } from 'react';
import { HelpBlock, Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import type { LogoFile, ImagePosition } from './types';
import { RemoveButton, LogoPreview, PositionButtonGroup } from './components';

import type { LogoRepository } from './LogoRepository';

type State = {
  logo?: ?LogoFile,
  imagePosition: ImagePosition,
};

type Props = {
  // A function that is called as soon as the stored logo is updated, either
  // with a new one or completely removed.
  onChange?: (file: ?LogoFile) => void,

  // The repository where the component will load/store the logo.
  repository: LogoRepository,
};

class LogoDropzone extends Component<Props, State> {
  changeLogoPosition: (position: ImagePosition) => void;
  updateLogo: (files: Array<$Shape<LogoFile>>) => void;
  removeLogo: () => void;

  constructor(props: Props) {
    super(props);

    const logo = this.props.repository.loadlogo();
    if (logo) {
      // NOTE: This is needed to inform DefaultTheme that a logo exists so that
      // it does not assign the hidden-print class in the container of
      // theLogoDropzone.
      this.onChange(logo);
    }

    this.state = {
      logo,
      imagePosition: 'center',
    };

    this.changeLogoPosition = this.changeLogoPosition.bind(this);
    this.updateLogo = this.updateLogo.bind(this);
    this.removeLogo = this.removeLogo.bind(this);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevState.logo &&
        this.state.logo &&
        prevState.logo.base64 !== this.state.logo.base64) ||
      (!prevState.logo && this.state.logo) ||
      (prevState.logo && !this.state.logo)
    ) {
      this.props.repository.storeLogo(this.state.logo);
      this.onChange(this.state.logo);
    }
  }

  onChange(file: ?LogoFile) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(file);
    }
  }

  changeLogoPosition(position: ImagePosition) {
    this.setState(() => ({ imagePosition: position }));
  }

  updateLogo(files: Array<$Shape<LogoFile>>) {
    if (files.length === 0) return;

    const file = files[0];
    //get base64 representation of the file
    //https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Example_Showing_thumbnails_of_user-selected_images
    const reader = new FileReader();
    reader.onload = e => {
      const fileAsBinaryString = reader.result;
      file.base64 = fileAsBinaryString;
      this.setState(prevState => {
        //release the existing object URL(dont keep the reference to the file anymore)
        if (prevState.logo && prevState.logo.preview) {
          URL.revokeObjectURL(prevState.logo.preview);
        }
        return { logo: file };
      });
    };
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.readAsDataURL(file);
  }

  removeLogo() {
    this.setState(prevState => ({
      logo: null,
      imagePosition: 'center',
    }));
  }

  render() {
    return (
      <div className="dropzone-container">
        {this.state.logo && this.state.logo.base64 ? (
          <React.Fragment>
            <LogoPreview
              base64={this.state.logo.base64}
              imagePosition={this.state.imagePosition}
            />
            <RemoveButton onClick={this.removeLogo} />
          </React.Fragment>
        ) : null}

        <Dropzone
          style={{ justifyContent: this.state.imagePosition }}
          className="dropzone hidden-print"
          accept="image/*"
          multiple={false}
          onDrop={this.updateLogo}
        >
          {this.state.logo && this.state.logo.base64 ? (
            <Image
              className="dropzone__image"
              src={this.state.logo.base64}
              rounded
            />
          ) : (
            <HelpBlock
              data-testid="logo-help-block"
              className="dropzone__help-block"
            >
              <FormattedMessage id="uploadLogo" />
              <span className="pt-15 dropzone__uploadlogo-prompt">
                <FormattedMessage id="uploadLogoPrompt" />
              </span>
            </HelpBlock>
          )}
        </Dropzone>

        {this.state.logo ? (
          <PositionButtonGroup changeLogoPosition={this.changeLogoPosition} />
        ) : null}
      </div>
    );
  }
}

export default LogoDropzone;
