// @flow

import React from 'react';
import { Row } from 'react-bootstrap';

import InvoiceTotalValue from './InvoiceTotalValue';

type Props = {
  isEmptyInvoice: boolean,
  taxWithHolding?: string,
  payableAmount?: string,
};

function TaxWithholding(props: Props) {
  return (
    <span>
      <Row className="invoice-total__row">
        <InvoiceTotalValue
          isEmptyInvoice={props.isEmptyInvoice}
          labelId="invoice.taxWithHolding"
          value={props.taxWithHolding}
          data-testid="invoice-total-tax-withholding"
        />
      </Row>

      <Row className="invoice-total__row">
        <InvoiceTotalValue
          isEmptyInvoice={props.isEmptyInvoice}
          labelId="invoice.payableAmount"
          value={props.payableAmount}
          data-testid="invoice-total-payable-amount"
        />
      </Row>
    </span>
  );
}

export default TaxWithholding;
