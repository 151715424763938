// @flow

import './index.css';

import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { emailMask } from '../masks';

import EditableLabel from '../EditableLabel';
import EmptyInvoiceLabel from '../EmptyInvoiceLabel';
import cx from 'classnames';

type Props = {
  name?: string,
  job?: string,
  address?: string,
  taxId?: string,
  taxOffice?: string,
  phone?: string,
  email?: string,
};

type State = {
  name?: string,
  job?: string,
  address?: string,
  taxId?: string,
  taxOffice?: string,
  phone?: string,
  email?: string,
};

class Client extends Component<Props, State> {
  nameRef: ?EditableLabel;
  jobRef: ?EditableLabel;
  addressRef: ?EditableLabel;
  taxIdRef: ?EditableLabel;
  taxOfficeRef: ?EditableLabel;
  phoneRef: ?EditableLabel;
  emailRef: ?EditableLabel;

  constructor(props: Props) {
    super(props);

    this.state = {
      name: props.name || '',
      job: props.job || '',
      address: props.address || '',
      taxId: props.taxId,
      taxOffice: props.taxOffice,
      phone: props.phone || '',
      email: props.email || '',
    };
  }

  reset() {
    [
      this.nameRef,
      this.jobRef,
      this.addressRef,
      this.taxIdRef,
      this.taxOfficeRef,
      this.phoneRef,
      this.emailRef,
    ].forEach(ref => {
      if (ref && typeof ref.reset === 'function') {
        ref.reset();
      }
    });
  }

  handleEditableLabelChange(field: string, value: string) {
    this.setState(prevState => ({
      [field]: value,
    }));
  }

  render() {
    const { name, job, address, taxId, taxOffice, phone, email } = this.props;
    const basicFilled =
      this.state.name &&
      this.state.job &&
      this.state.address &&
      this.state.taxId &&
      this.state.taxOffice;

    return (
      <Grid fluid>
        <Row className="client__row">
          {!this.state.name && (
            <EmptyInvoiceLabel label={<FormattedMessage id="client.name" />} />
          )}
          <EditableLabel
            data-testid="client-name"
            ref={editableLabel => {
              this.nameRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={name}
            placeholder={<FormattedMessage id="client.name" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('name', value)}
          />
        </Row>

        <Row className="client__row">
          {!this.state.job && (
            <EmptyInvoiceLabel label={<FormattedMessage id="client.job" />} />
          )}
          <EditableLabel
            data-testid="client-job"
            ref={editableLabel => {
              this.jobRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={job}
            placeholder={<FormattedMessage id="client.job" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('job', value)}
          />
        </Row>

        <Row className="client__row">
          {!this.state.address && (
            <EmptyInvoiceLabel label={<FormattedMessage id="address" />} />
          )}
          <EditableLabel
            data-testid="client-address"
            ref={editableLabel => {
              this.addressRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={address}
            placeholder={<FormattedMessage id="address" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('address', value)}
          />
        </Row>

        <Row className="client__row">
          {!this.state.taxId && (
            <EmptyInvoiceLabel label={<FormattedMessage id="taxid" />} />
          )}

          <b className={cx('nowrap', { 'hidden-print': !this.state.taxId })}>
            <FormattedMessage id="taxid" />
            &#58;&nbsp;
          </b>

          <EditableLabel
            data-testid="client-taxid"
            ref={editableLabel => {
              this.taxIdRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={16} />}
            initialValue={taxId}
            placeholder={<FormattedMessage id="taxid" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('taxId', value)}
          />
        </Row>

        <Row className="client__row">
          {!this.state.taxOffice && (
            <EmptyInvoiceLabel label={<FormattedMessage id="taxoffice" />} />
          )}

          <b
            className={cx('nowrap', { 'hidden-print': !this.state.taxOffice })}
          >
            <FormattedMessage id="taxoffice" />
            &#58;&nbsp;
          </b>

          <EditableLabel
            data-testid="client-taxoffice"
            ref={editableLabel => {
              this.taxOfficeRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={taxOffice}
            placeholder={<FormattedMessage id="taxoffice" />}
            className="client__label"
            onChange={value =>
              this.handleEditableLabelChange('taxOffice', value)
            }
          />
        </Row>

        <Row className="client__row">
          {!this.state.phone &&
            !basicFilled && (
              <EmptyInvoiceLabel label={<FormattedMessage id="phone" />} />
            )}

          <EditableLabel
            data-testid="client-phone"
            ref={editableLabel => {
              this.phoneRef = editableLabel;
            }}
            as={props => <input {...props} minLength={0} maxLength={70} />}
            initialValue={phone}
            placeholder={<FormattedMessage id="phone" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('phone', value)}
          />
        </Row>

        <Row className="client__row">
          {!this.state.email &&
            !basicFilled && (
              <EmptyInvoiceLabel label={<FormattedMessage id="email" />} />
            )}

          <EditableLabel
            data-testid="client-email"
            ref={editableLabel => {
              this.emailRef = editableLabel;
            }}
            as={props => {
              return <MaskedInput {...props} mask={emailMask} guide={true} />;
            }}
            initialValue={email}
            placeholder={<FormattedMessage id="email" />}
            className="client__label"
            onChange={value => this.handleEditableLabelChange('email', value)}
          />
        </Row>
      </Grid>
    );
  }
}
export default Client;
