import './index.scss';

import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'ΕΛ',
    };

    this.onLanguageSelect = this.onLanguageSelect.bind(this);
  }

  onLanguageSelect(selectedLang) {
    const LANG = { en: 'EN', el: 'ΕΛ' };

    this.props.onLanguageChange(selectedLang);
    this.setState(prevState => ({
      language: LANG[selectedLang],
    }));
  }

  render() {
    return (
      <Dropdown id="lang__dropdown" onSelect={this.onLanguageSelect}>
        <Dropdown.Toggle
          data-testid="languages-dropdown-current"
          className="lang__dropdown-btn"
        >
          {this.state.language}
        </Dropdown.Toggle>
        <Dropdown.Menu className="lang__dropdown-menu">
          <MenuItem data-testid="languages-dropdown-option-el" eventKey="el">
            ΕΛ
          </MenuItem>
          <MenuItem data-testid="languages-dropdown-option-en" eventKey="en">
            EN
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default LanguageSwitcher;
