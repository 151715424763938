// @flow

import './index.scss';

import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import classNames from 'classnames';
import Label from './Label';

type Props = {
  'data-testid'?: string,
  as: React$ElementType,
  className?: string,
  editMode: boolean,
  multiline: boolean,
  initialValue: string,
  initialValueOnClick?: string,
  placeholder?: React$Node,
  onChange?: (value: string) => mixed,
};

type State = {
  inEdit: boolean,
  value: string,
};

class EditableLabel extends Component<Props, State> {
  InputComponent: React$ElementType;

  constructor(props: Props) {
    super(props);

    this.state = {
      inEdit: props.editMode,
      value: props.initialValue,
    };

    /*
     * InputComponent is meant to be passed as the `componentClass` prop of the
     * FormControl component. It is declared here as an instance property
     * instead at the point of use of FormControl. This way it will not be
     * created each time a render occurs leading to perfomance and focus related
     * issues.
     */
    const InputComponent = this.props.as;
    this.InputComponent = props => {
      return <InputComponent {...props} data-testid="editable-input" />;
    };
  }

  static defaultProps = {
    as: 'input',
    editMode: false,
    multiline: false,
    initialValue: '',
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.inEdit &&
      !this.state.inEdit &&
      typeof this.props.onChange === 'function'
    ) {
      this.props.onChange(this.state.value);
    }
  }

  switchToViewMode() {
    this.setState(() => ({ inEdit: false }));
  }

  swithcToEditMode() {
    this.setState(() => ({ inEdit: true }));
  }

  onChange(value: string) {
    this.setState(() => ({ value }));
  }

  onKeyUp(e: SyntheticKeyboardEvent<>) {
    const key = e.key;
    const isShiftPressed = e.shiftKey;
    if (key === 'Escape' || (key === 'Enter' && !isShiftPressed)) {
      this.switchToViewMode();
    }
  }

  onLabelClick() {
    if (this.props.initialValueOnClick && !this.state.value) {
      this.setState(
        () => ({ value: this.props.initialValueOnClick }),
        this.swithcToEditMode()
      );
    } else {
      this.swithcToEditMode();
    }
  }

  reset() {
    this.setState(() => ({ value: this.props.initialValue }));
  }

  render() {
    return (
      <div
        data-testid={this.props['data-testid']}
        className={classNames('editable', this.props.className)}
        onFocus={() => this.onLabelClick()}
        tabIndex="0"
      >
        {this.state.inEdit ? (
          <FormControl
            className="editable__label-input"
            type="text"
            componentClass={this.InputComponent}
            value={this.state.value}
            onChange={(e: SyntheticInputEvent<>) =>
              this.onChange(e.target.value)
            }
            onKeyUp={(e: SyntheticKeyboardEvent<>) => this.onKeyUp(e)}
            onBlur={() => this.switchToViewMode()}
            autoFocus
            bsSize="sm"
          />
        ) : (
          <Label
            className={classNames({
              'text-muted': !this.state.value,
              'hidden-print': !this.state.value,
            })}
            multiline={this.props.multiline}
            onClick={() => this.onLabelClick()}
          >
            {this.state.value || this.props.placeholder}
          </Label>
        )}
      </div>
    );
  }
}

export default EditableLabel;
