// @flow

import './index.css';

import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';
import { invoiceNumberMask, createDateMask } from '../masks';

import EmptyInvoiceLabel from '../EmptyInvoiceLabel';
import EditableLabel from '../EditableLabel';

function LastInvoiceNumberMemo({ invoiceNumber }: { invoiceNumber: string }) {
  return (
    <p
      data-testid="invoice-number-memo"
      className="invoice-meta__invoiceNum--last hidden-print"
    >
      <FormattedMessage id="invoice.lastNumber">
        {(txt: string) => `${txt}: ${invoiceNumber}`}
      </FormattedMessage>
    </p>
  );
}

type Props = {
  number: string,
  date: string,
};

type State = {
  invoiceNumber: string,
  currentNumber: string,
  date: string,
};

class InvoiceMeta extends Component<Props, State> {
  invoiceNumberRef: ?EditableLabel;
  invoiceDateRef: ?EditableLabel;

  constructor(props: Props) {
    super(props);
    //retrieve from localstorage if any
    const storedInvoiceNumber =
      window.localStorage && window.localStorage.getItem('invoiceNumber');
    this.state = {
      invoiceNumber: storedInvoiceNumber || this.props.number,
      currentNumber: this.props.number,
      date: '',
    };
  }

  reset() {
    [this.invoiceNumberRef, this.invoiceDateRef].forEach(ref => {
      if (ref && typeof ref.reset === 'function') {
        ref.reset();
      }
    });
  }

  handleInvoiceNumberChange(value: string) {
    const localStorage = window.localStorage;
    this.setState(prevState => ({
      invoiceNumber: value,
    }));
    if (localStorage) {
      localStorage.setItem('invoiceNumber', value);
    }
  }

  handleEditableLabelChange(field: string, value: string) {
    this.setState(prevState => ({
      [field]: value,
    }));
  }

  render() {
    return (
      <Grid fluid>
        <Row className="invoice-meta__row wrap">
          {!this.state.currentNumber && (
            <EmptyInvoiceLabel
              label={<FormattedMessage id="invoice.number" />}
            />
          )}

          <b
            className={cx('nowrap invoice-meta__prompt', {
              'hidden-print': !this.state.currentNumber,
            })}
          >
            <FormattedMessage id="invoice.number" />
            &#58;&nbsp;
          </b>
          <EditableLabel
            ref={editableLabel => {
              this.invoiceNumberRef = editableLabel;
            }}
            as={props => {
              return (
                <MaskedInput
                  {...props}
                  mask={invoiceNumberMask}
                  placeholderChar=" "
                  guide={true}
                />
              );
            }}
            className="invoice-meta__label invoice-meta__invoiceNumRow"
            placeholder="#"
            onChange={value => {
              this.handleInvoiceNumberChange(value);
              this.handleEditableLabelChange('currentNumber', value);
            }}
          />
        </Row>
        <Row className="hidden-print">
          {this.state.invoiceNumber ? (
            <LastInvoiceNumberMemo invoiceNumber={this.state.invoiceNumber} />
          ) : null}
        </Row>
        <Row className="invoice-meta__row">
          {!this.state.date && (
            <EmptyInvoiceLabel label={<FormattedMessage id="invoice.date" />} />
          )}

          <b
            className={cx('nowrap invoice-meta__prompt', {
              'hidden-print': !this.state.date,
            })}
          >
            <FormattedMessage id="invoice.date" />
            &#58;&nbsp;
          </b>

          <EditableLabel
            data-testid="invoice-meta-date"
            ref={editableLabel => {
              this.invoiceDateRef = editableLabel;
            }}
            as={props => {
              return (
                <MaskedInput
                  {...props}
                  mask={createDateMask()}
                  guide={true}
                  showMask={true}
                />
              );
            }}
            placeholder={<FormattedMessage id="invoice.datePlaceholder" />}
            initialValueOnClick={this.props.date}
            className="invoice-meta__label"
            onChange={value => this.handleEditableLabelChange('date', value)}
          />
        </Row>
      </Grid>
    );
  }
}

export default InvoiceMeta;
