// @flow

import './index.css';

import React, { Component } from 'react';
import { Grid, Row, Col, Checkbox } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { formatEuros } from '../../utils';

import { InvoiceTotalMainPart, TaxWithholding } from './components';

type Props = {
  isEmptyInvoice: boolean,
  totalCostNoVat?: string,
  vat?: string,
  totalCostWithVat?: string,
  taxWithHolding?: string,
  payableAmount?: string,
  hasTaxWithHolding?: boolean,
  toggleTaxWithHolding?: () => mixed,
};

class InvoiceTotal extends Component<Props> {
  static defaultProps = {
    isEmptyInvoice: false,
    totalCostNoVat: formatEuros(0),
    vat: formatEuros(0),
    totalCostWithVat: formatEuros(0),
    taxWithHolding: formatEuros(0),
    payableAmount: formatEuros(0),
    hasTaxWithHolding: false,
    toggleTaxWithHolding: () => {},
  };

  render() {
    const {
      isEmptyInvoice,
      totalCostNoVat,
      vat,
      totalCostWithVat,
      taxWithHolding,
      payableAmount,
      hasTaxWithHolding,
      toggleTaxWithHolding,
    } = this.props;

    return (
      <Grid fluid>
        <InvoiceTotalMainPart
          isEmptyInvoice={isEmptyInvoice}
          totalCostNoVat={totalCostNoVat}
          vat={vat}
          totalCostWithVat={totalCostWithVat}
        />

        <Row className="invoice-total__row hidden-print">
          <Col xs={12} className="text-right">
            <Checkbox
              inline
              data-testid="invoice-total-checkbox-tax-withholding"
              className="invoice-total__withHolding"
              checked={hasTaxWithHolding}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                if (typeof toggleTaxWithHolding === 'function') {
                  toggleTaxWithHolding();
                }
              }}
            >
              <FormattedMessage id="invoice.taxWithHoldingCap" />
            </Checkbox>
          </Col>
        </Row>

        {hasTaxWithHolding ? (
          <TaxWithholding
            isEmptyInvoice={isEmptyInvoice}
            taxWithHolding={taxWithHolding}
            payableAmount={payableAmount}
          />
        ) : null}
      </Grid>
    );
  }
}

export default InvoiceTotal;
