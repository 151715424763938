// @flow

import './index.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
  as: React$ElementType,
  multiline: boolean,
  className?: string,
  children?: React$Node,
  onClick: () => mixed,
};

function Label({
  as: Component,
  multiline,
  className,
  children,
  onClick,
}: Props) {
  return (
    <Component
      data-testid="editable-label"
      className={classNames(
        'editable__label',
        { 'editable__label--multiline': multiline },
        className
      )}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}

Label.defaultProps = { as: 'pre', multiline: false };

export default Label;
