import './index.css';

import React from 'react';

function EmptyInvoiceLabel({ label }) {
  return (
    <span data-testid="empty-invoice-label" className="visible-print">
      <div className="emptyinvoicelabel__wrapper">
        <b className="emptyinvoicelabel__label">{label}: </b>
        <div className="emptyinvoicelabel__placeholder"> </div>
      </div>
    </span>
  );
}

export default EmptyInvoiceLabel;
