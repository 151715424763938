// @flow

import './index.css';

import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { createNaturalNumberMask, createCurrencyMask, vatMask } from '../masks';

import { ItemTableHeader, IconActionButton, AddNewItemRow } from './components';
import EditableLabel from '../EditableLabel';

import { InvoiceItem, InvoiceItemList } from '../../domain';
import { EURO, formatEuros, formatVat } from '../../utils';

type Props = {
  addNewItem: () => mixed,
  removeItem: (itemId: string) => mixed,
  updateItem: (item: InvoiceItem) => mixed,
  items: InvoiceItemList,
};

class ItemTable extends Component<Props> {
  static defaultProps = {
    items: [],
  };

  renderEmptyRows(num: number) {
    let rows = [];
    for (let i = 0; i < num; i++) {
      rows.push(
        <tr key={i} className="emptyRow visible-print">
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      );
    }
    return rows;
  }

  render() {
    const { items } = this.props;
    return (
      <Table striped responsive>
        <ItemTableHeader />

        <tbody>
          <AddNewItemRow addNewItem={this.props.addNewItem} />
          {items && items.length === 0 && this.renderEmptyRows(12)}
          {items.map((item, index) => {
            return (
              <tr key={item.id}>
                <td className="w-5 hidden-print">
                  <IconActionButton
                    glyph="remove"
                    onClick={() => this.props.removeItem(item.id)}
                  />
                </td>

                <td className="w-5">{index + 1}</td>

                <td className="w-45">
                  <EditableLabel
                    initialValue={item.description}
                    placeholder={<FormattedMessage id="item.description" />}
                    onChange={description =>
                      this.props.updateItem(
                        new InvoiceItem({ ...item, description })
                      )
                    }
                  />
                </td>

                <td className="w-10">
                  <EditableLabel
                    as={props => {
                      return (
                        <MaskedInput
                          {...props}
                          mask={createNaturalNumberMask()}
                          guide={true}
                        />
                      );
                    }}
                    initialValue={item.quantity.toString()}
                    onChange={quantity =>
                      this.props.updateItem(
                        new InvoiceItem({
                          ...item,
                          quantity: parseInt(quantity, 10),
                        })
                      )
                    }
                  />
                </td>

                <td className="w-10">
                  <EditableLabel
                    as={props => {
                      return (
                        <MaskedInput
                          {...props}
                          mask={createCurrencyMask(EURO)}
                          guide={true}
                        />
                      );
                    }}
                    initialValue={formatEuros(item.cost)}
                    onChange={cost =>
                      this.props.updateItem(
                        new InvoiceItem({ ...item, cost: parseFloat(cost) })
                      )
                    }
                  />
                </td>

                <td className="w-10">
                  <EditableLabel
                    as={props => {
                      return <MaskedInput {...props} mask={vatMask} />;
                    }}
                    initialValue={formatVat(item.vat)}
                    onChange={vat =>
                      this.props.updateItem(
                        new InvoiceItem({ ...item, vat: parseFloat(vat) })
                      )
                    }
                  />
                </td>

                <td align="right" className="w-10">
                  {formatEuros(item.calculateTotal())}
                  <IconActionButton
                    className="table__footer-button"
                    glyph="plus"
                    onClick={this.props.addNewItem}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default ItemTable;
