// @flow

export type InvoiceItemId = string;

type InvoiceItemCtorParams = {
  id?: InvoiceItemId,
  description?: string,
  quantity?: number,
  cost?: number,
  vat?: number,
};

function generateId() {
  return Math.random()
    .toString(36)
    .replace('0.', '');
}

// TODO: This is a value object and must be transformed to an immutable one
export class InvoiceItem {
  id: InvoiceItemId;
  description: string;
  quantity: number;
  cost: number;
  // NOTE: VAT is an integer value [0-100] representing a percentage.
  vat: number;

  constructor({
    id,
    description = '',
    quantity = 1,
    cost = 0,
    vat = 24,
  }: InvoiceItemCtorParams = {}) {
    this.id = id || generateId();
    this.description = description;
    this.quantity = isNaN(quantity) ? 1 : quantity;
    this.cost = isNaN(cost) ? 0 : cost;
    this.vat = isNaN(vat) ? 0 : vat;
    if (this.vat > 100) {
      this.vat = 100;
    }
  }

  calculateVat(): number {
    const { cost, quantity, vat } = this;
    if (cost <= 0 || quantity <= 0 || vat <= 0) return 0;

    return quantity * ((cost * vat) / 100);
  }

  calculateTotal(): number {
    const { cost, quantity, vat } = this;
    if (cost < 0 || quantity < 0 || vat < 0) return 0;

    return quantity * ((cost * (100 + vat)) / 100);
  }
}
