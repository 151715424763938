import React from 'react';
import { Image } from 'react-bootstrap';

import type { ImagePosition } from '../types';

type LogoPreviewProps = {
  base64: string,
  imagePosition?: ImagePosition,
};

function LogoPreview({ base64, imagePosition }: LogoPreviewProps) {
  return (
    <span className="visible-print image-preview">
      <div
        data-testid="logo-preview-image"
        style={{
          display: 'flex',
          justifyContent: imagePosition,
        }}
      >
        <Image className="dropzone__image" src={base64} rounded />
      </div>
    </span>
  );
}

LogoPreview.defaultProps = { imagePosition: 'center' };

export default LogoPreview;
